import React from "react"
import Layout from "../../components/layout"

export default function TeamGirlsAllStars() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Leeds City Girls All Stars (4-12)</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
                <img src="/instinct-allstars.jpg" className="img-fluid mb-4" />
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>Pre School to School Year 8</dd>
                <dt>Manager(s)</dt>
                <dd>Nathan Hunt</dd>
                <dt>Training</dt>
                <dd>Wednesday 5:30pm-6:30pm</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:instinctsportscoaching@gmail.com">instinctsportscoaching@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-kipmcgrath.jpg" />
                <div className="card-body">
                    <p className="card-text">Kip McGrath provides live and interactive lessons online or in centre, tailored to your child’s needs.</p>
                    <div className="btn-group">
                    <a href="https://www.kipmcgrath.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

